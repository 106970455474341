import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import styles from "../style/templates/blogpost.module.scss";

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  return (
    <Layout title="J. Scott George">
      <div className={styles.blogPost}>
        <Link className={styles.back} to="/blog">
          {"< BACK"}
        </Link>
        <article className="post-content">
          {post.frontmatter.cover && <img src={post.frontmatter.cover} />}
          <label>{post.frontmatter.date}</label>
          <h1>{post.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cover
      }
    }
  }
`;
